import { Component, OnInit, inject } from '@angular/core';
import { PrintHistory, TargetDocument } from '../../model';
import { SharedService } from '../shared.service';
import { MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';
import { groupBy, sortBy, reverse } from 'lodash';
import { TranslateModule } from '@ngx-translate/core';
import { NgStyle, DatePipe } from '@angular/common';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-printing-history',
    templateUrl: './printing-history.component.html',
    styleUrls: ['./printing-history.component.scss'],
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        NgStyle,
        DatePipe,
        TranslateModule,
    ]
})
export class PrintingHistoryComponent implements OnInit {
	data = inject(MAT_DIALOG_DATA);
	private shared = inject(SharedService);

	printingHistory: PrintHistory[] = [];
	docsPrintingCounts: { count: number; doc: TargetDocument }[];

	ngOnInit(): void {
		this.shared
			.getPrintHistory(this.data.targetDocuments, this.data.documentId)
			.subscribe(value => {
				this.printingHistory = reverse(sortBy(value, ['dateTime']));
				const docs = groupBy(value, 'targetDocument');
				this.docsPrintingCounts = this.data.targetDocuments.map(doc => {
					return {
						doc: doc,
						count: docs[doc]?.length,
					};
				});
			});
	}
}
